import "intersection-observer"
import { PROD_SITE } from '@/const/prod.const';
import u from '@/util';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useConfBase from "@/hooks/useConfBase";
import { generateFullUrl, appendHost } from "@/const/append"


const regex = /(http|https):\/\//;

interface IImg {
    src: string;
    alt?: string;
    width?: number;
    height?: number;
    fill?: boolean;
    style?: CSSProperties,
    cls?: string;
    id?: string;
    ref?: any
    // 默认图
    errorImg?: 1 | 2 | 3,
    click?: () => void;
    errorCheck?: boolean;
    encryptUrl?: string;
    reload?: boolean; // 加密reload
    children?: any
    onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void; // Add this line

}

const Img: FC<IImg> = ({ children, src, alt, width, height, fill, style, cls, id, errorImg, click, errorCheck, encryptUrl , reload }) => {
    const [config] = useConfBase();
    const { ref, inView, entry } = useInView({
        rootMargin: '0px',
        threshold: 0.5,
    });

    const [error, setError] = useState<boolean>(false);
    const [imgSrc, setImgSrc] = useState<string>();

    useEffect(() => {
        if (src) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            console.log('src exist: '+ src + '- url -'+ encryptUrl);
            if (!encryptUrl) { encryptUrl = src; }
            if (!src.includes('loading') && src.includes('wsSecret')) {
                return setImgSrc(src);
            }

            let _src = src;

            const isBase64 = _src.indexOf("data:") >= 0;
            if (!isBase64 && !regex.test(_src) && src.includes('http')) {
                _src = generateFullUrl(_src, config.encrypted_image_domain);
            }
            setImgSrc(_src);
        } else {
            console.log('src no exist: '+ encryptUrl);
            const fetchData = async () => {
                const fetchAssetImg = await u.fetchAssetImg(generateFullUrl(`/images/default/loading/750x422.jpg`, config.encrypted_image_domain));
                if (fetchAssetImg) return setImgSrc(fetchAssetImg);
            };
            fetchData();
        }
    }, [src, config.image_source_url]);

    useEffect(() => {
        if (error) {
            let _v = "/images/default/loading/750x422.jpg";
            if (errorImg === 2) {
                _v = "/images/default/loading/790x530.jpg";
            }
            const newSrc = generateFullUrl(_v, config.encrypted_image_domain);
            setImgSrc(newSrc);
        }
    }, [errorImg, error, src, config.image_source_url]);

    const errHandler = useCallback(() => {
        setError(true);
    }, []);

    const decryptImg = useCallback(async () => {
        if (imgSrc && !imgSrc.includes("loading")) return;

        let finalSrc = src;

        if (encryptUrl) {
            console.log('decryptImg exist: '+ encryptUrl);

            let sourcurl = config.encrypted_image_domain ?? process.env.REACT_APP_STATIC_SOURCE_URL;

            // let url = generateFullUrl(encryptUrl, sourcurl).replace(config.image_url + "/", "").replaceAll('//', '/');
            // finalSrc = url;

            let url = encryptUrl.replaceAll('//', '/');
            const vidKeyParam = u.addImgKeyParam(url);
            const dynamicEncryptUrl = `${sourcurl}${url}${vidKeyParam}`;
            finalSrc =  dynamicEncryptUrl;
        } else {
            console.log('decryptImg no exist: '+ src);

            if (src && !src.includes(config.image_source_url) && !src.startsWith("http")) {
                finalSrc = appendHost(src, config.image_source_url);
            }
        }

        if (!finalSrc?.includes('loading')) {
            // console.log(`decryptImg==========> ${finalSrc}`);
        }

        let res;
        if (finalSrc?.includes("wsSecret")) {
            return setImgSrc(finalSrc);
        } else {
            res = await u.fetchData(finalSrc);
        }

        if (res) {
            if (typeof res === "string" && res.includes("404 Not Found")) {
                const fetchAssetImg = await u.fetchAssetImg(generateFullUrl('/images/default/loading/750x422.jpg', config.encrypted_image_domain));
                if (fetchAssetImg) return setImgSrc(fetchAssetImg);
            }

            let __decrypted = "";
            try {
                __decrypted = res.indexOf("data") >= 0 ? res : u.imgDecrypt(res);
            } catch {
                const fetchAssetImg = await u.fetchAssetImg(generateFullUrl('/images/default/loading/750x422.jpg', config.encrypted_image_domain));
                if (fetchAssetImg) return setImgSrc(fetchAssetImg);
            }
            return setImgSrc(__decrypted);
        } else {
            const fetchAssetImg = await u.fetchAssetImg(generateFullUrl('/images/default/loading/750x422.jpg', config.encrypted_image_domain));
            if (fetchAssetImg) return setImgSrc(fetchAssetImg);
        }
    }, [encryptUrl, imgSrc]);

    useEffect(() => {
        console.log('imgsrc - ' + imgSrc + ' - src -' + src + ' - en - '+ encryptUrl);
        if (`${imgSrc}`.includes("loading") && inView) {
            decryptImg();
        }

        if (`${imgSrc}`.includes("R0lGODlhGgI6APf") && inView) {
            decryptImg();
        }

        if ((!`${imgSrc}`.includes("data")) && `${src}`.includes('/images/')) {
            decryptImg();
        }
    }, [encryptUrl, inView, decryptImg, imgSrc, src]);

    const actualImgEl = useMemo(() => {
        return <img ref={ref} onClick={click} id={id} onError={errHandler} style={style} sizes="100vh" className={`point fill ${cls}`} height={height || 0} width={width || 0} alt={alt || ''} src={imgSrc} />;
    }, [ref, click, id, errHandler, style, cls, height, width, alt, imgSrc]);

    return (
        <>
            {errorCheck ? (error ? '' : actualImgEl) : actualImgEl}
            {children && children(imgSrc)}
        </>
    );
};

export default Img;