import CategoryHead from "@/components/categoryHead";
import useUser from "@/hooks/useUser";
import { FC, useCallback, useEffect, useState } from "react";
import Img from "@/components/img";
import { appendHost } from "@/const/append";
import styles from "./styles/info.module.css"
import u, { ELocalKey } from "@/util";
import Back from "@/components/back";
import useRoleAuth from "@/hooks/useRoleAuth";
import Footer from "@/components/footer";
import useConfBase from "@/hooks/useConfBase";
import useAxios from "@/hooks/useAxios";

const UserInfo: FC = () => {
    useRoleAuth()
    const [userInfo] = useUser();
    const [id, setId] = useState()
    const [info, setInfo] = useState<any>()
    const [config, updateConfBase, getData] = useConfBase();
    const { req: reqUnseenCount } = useAxios("message/unseenCount", "post");
    const [msgAmt, setMsgAmt] = useState<any>(0)

    const fnExecute = useCallback((item: any) => {
        if (item?.event) {
            return item.event()
        }
        if (item?.url) {
            return window.open(item.url)
        }
    }, [])

    const fetchMsgAmt = useCallback(async () => {
        if (!userInfo?.token) return;

        try{
            if(localStorage.getItem(ELocalKey._MSGUNSEEN) && 
            (localStorage.getItem(ELocalKey._NOTICE) && new Date().getHours().toString() === localStorage.getItem(ELocalKey._NOTICE))
            ){
                setMsgAmt(parseInt(localStorage.getItem(ELocalKey._MSGUNSEEN) ?? "0"))
            }else{
                const res = await reqUnseenCount({
                    token: userInfo?.token
                });
        
                console.log('reqUnseenCount message  ', res)
                if (res) {
                    setMsgAmt(res?.data?.unseen_count)
                    localStorage.setItem(ELocalKey._MSGUNSEEN, res?.data?.unseen_count);
                }
            }
        }catch(err){
            setMsgAmt(0)
        }

    }, [userInfo])

    
    useEffect(() => {
        fetchMsgAmt()
    }, [fetchMsgAmt])

    useEffect(() => {
        getData(2).then((res: any) => {
            if(res && res.length){
                try{
                    if(res && res.length){
                        console.log('goods' , res)
                        localStorage.setItem("__GD", JSON.stringify(res));
                    }
                }catch(err){
                }
                res.map((item: any) => {
                    if(item.title === '终身VIP'){
                        setId(item.id)
                        setInfo(item)
                    }
                })
            }
            console.log(res)
        });
      }, [getData, config])


    return (
        <div>
            <CategoryHead showCategory={false}>
                <div className={`relative h1200`}>
                    <div className={styles.w902}>
                        <Img alt="" style={{ width: '100%', height: '400px' }} src={appendHost("/images/theme/user/info_top_bg.png")}/>
                    </div>
                    <div className={styles.container}>
                        <div className="fl">
                            <Back right hideIcon>
                                {/* {userInfo.val_time !== '0' && <div className="c1 mlauto">VIP到期时间: {u.toFmt(userInfo.val_time * 1000)}</div>} */}
                            </Back>
                        </div>
                        <div className={`fl gap30 align_center justify_center ${styles.mt40}`}>
                            <Img width={60} height={60} src={appendHost("/images/theme/user/user.png")} />
                            <div className="grid gap5" style={{width: '60%'}}>
                                <div className={styles.name}>{userInfo?.username}</div>
                                {/* 0 = 普通用户 , 1 = VIP 用户 , 2 = 永久VIP */}
                                <div className="c1 fl">{userInfo?.is_vip === '2' ? '永久VIP用户' : userInfo?.is_vip === '1' ? 'VIP用户' : '普通用户'} 
                                {userInfo?.is_vip === '1' && <>
                                ({userInfo.val_time !== '0' && <div className="c1">截止日期: {u.toFmt(userInfo.val_time * 1000)}</div>})
                                </>}
                                </div>
                                <div className="c1">猫咪ID: {userInfo?.id}</div>
                            </div>
                        </div>
                        <div className={`grid gap20 ${styles.mt120}`}>
                            <div className={`${styles.inner_content} grid gap20`}>
                                <div className="fl gap20 justify_center align_center">

                                    {
                                        `${userInfo?.is_vip}` === '2' ? <>
                                            <div className="relative w100">
                                                <div className={styles.vip_level_2}>尊敬的猫咪VIP用户</div>
                                                <div className={styles.benefits}>您已获得以下权益</div>
                                                <Img cls={styles.yongjiu} src={appendHost("/images/user-center/info/yongjiu.png")} />
                                            </div>
                                        </> :
                                            <>
                                                <div className={`${styles.img_text} fz16`}>终身VIP</div>
                                                <div className="fl align_center gap10">
                                                    <div className={styles.original_price}>原价<span>{info?.old_cost}</span></div>
                                                    <div className="relative">
                                                        <div className={styles.new_price}>限时优惠￥{info?.cost}</div>
                                                        <div onClick={() => window.open(`/user/charge?id=${id}`)} className={styles.buy_btn}>立即拥有</div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>
                                <div className={`fl gap20 justify_center ${styles.img_p}`}>
                                    {imgs.map((x) => {
                                        return <div className={`grid gap10 justify_center fl1`}>
                                            <div className={styles.img_item}>
                                                <Img width={51} height={51} src={x.img} />
                                            </div>
                                            <div className={styles.img_text}>
                                                {x.name}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className={styles.btm_container}>
                                <div className="grid gap20">
                                    {eventl(userInfo).map((x, i) => {
                                        return <div key={i} className={`fl justify_between point ${styles.item_btm}`} onClick={() => fnExecute(x)}>
                                            {
                                                x.url ? <div className="a_b">
                                                    <div className={`${styles.left}`} style={{display:'flex', alignItems:'center', gap:'5px'}}>
                                                        {x.name}
                                                        {x.name === "消息中心" && !!msgAmt && msgAmt > 0 && <>
                                                                    <div className={styles.msgamt}>{msgAmt}</div>
                                                                </>}
                                                        </div>
                                                    </div> : 
                                                    <div className={`${styles.left} point`}>{x.name}</div>
                                            }
                                            <div className={`${styles.right} point`}>{x.title}</div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CategoryHead>
            <div className="mt100">
                <Footer />
            </div>
        </div>
    )
}
export default UserInfo;

const imgs = [
    {
        name: "私人定制",
        img: appendHost("/images/user-center/info/vip_srdz.png")
    },
    {
        name: "专属客服",
        img: appendHost("/images/user-center/info/vip_zskf.png")
    },
    {
        name: "无限观看",
        img: appendHost("/images/user-center/info/vip_srdz.png")
    },
    {
        name: "没有广告",
        img: appendHost("/images/user-center/info/vip_wxgk.png")
    },
    {
        name: "VIP专区",
        img: appendHost("/images/user-center/info/vip_mygg.png")
    },
    {
        name: "高清线路",
        img: appendHost("/images/user-center/info/vip_gqxl.png")
    },
];


const eventl = (user: any) => [
    {
        name: "购买VIP",
        title: "获取最新优惠",
        url: '/user/buy'
    },
    {
        name: "兑换码",
        title: "兑换VIP、主题",
        url: '/user/redeem'
    },
    {
        name: "已购买主题",
        title: "优质资源下载",
        url: '/user/topicBill'
    },
    {
        name: "购买流水",
        title: "消费流水记录",
        url: '/user/bill'
    },

    {
        name: "消息中心",
        title: "查看系统消息",
        url: '/user/message'
    },
    {
        name: "在线客服",
        title: "24小时咨询",
        event: () => {
            return window.open(user?.custom_url)
        }
    },
    {
        name: "福利开车群",
        title: "一起开黄腔",
        event: () => {
            return window.open('https://t.me/maomiresource')
        }
    },
    {
        name: "设置",
        title: "手机绑定",
        url: '/user/setting'
    },
    // {
    //     name: "兑换码",
    //     title: "兑换VIP",
    //     url: '/user/code'
    // },


    {
        name: "退出登录",
        event: u.logout
    },
]
