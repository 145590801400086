import { FC, useCallback, useEffect, useState } from "react"
import Img from "./img"
import { appendHost } from "@/const/append";

import styles from "./styles/download.module.css"
import useConfBase from "@/hooks/useConfBase"
// import useWindowLoadCallback from "@/hooks/useWindowEffect"
import u, { ELocalKey } from "@/util"

const Download: FC = () => {
    const [config, updateConfBase, getData] = useConfBase();
    const [hide, setHide] = useState<boolean>(false)
    const [dwn, setDwn] = useState<string>("")
    // useEffect(() => {
    //     getData(1).then((res: any) => {
    //         setDwn(res?.maomiapk?.url)
    //     })
    // }, [config])

    // useWindowLoadCallback(() => {
    //     const res = u.getScriptVal(1) as any;
    //     if (res?.maomiapk?.url) {
    //         setDwn(res?.maomiapk?.url);
    //     }
    // })

    const clickDl = useCallback(async () => {
        window.open('/download');
        // const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__MM));
        // if (__data && __data?.maomiapk?.url) {
        //     window.open(__data.maomiapk.url)
        // }
    }, [])

    useEffect(() => {
        u.timeoutCb(() => {
            const __data = u.getStringtifyVal(localStorage.getItem(ELocalKey.__MM));
            if (__data && __data?.maomiapk?.url) {
                setDwn(__data.maomiapk.url);
            }
        })
    }, [])

    const closeDwn = useCallback((e: any) => {
        setHide(true)
    }, [])

    return <>
        {!hide && <div className={styles.d}>
            <div onClick={() => clickDl()}>
                <Img src={appendHost("/images/header/logo.png")} />
                <Img src={appendHost("/images/header/dwn.gif")} />
                <div>体验精彩视频 </div>
            </div>
            <div onClick={closeDwn} className={styles.close}>
                <Img width={20} height={20} src={appendHost("/images/icon/close.png")} />
            </div>
        </div>}
    </>

}
export default Download