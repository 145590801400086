import ImageList from "@/components/list";
import Back from "@/components/back";
import Footer from "@/components/footer";
import Pagination from "@/components/pagination";
import useAxios from "@/hooks/useAxios";
import useCategory from "@/hooks/useCategory";
import u from "@/util";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface ICategorySound {
}

const Sound: FC<ICategorySound> = () => {
    const { channel, id } = useParams()
    const category = useCategory()
    const { req: req2 } = useAxios("list/base", "post");
    const [dta, setDta] = useState<any>({})
    const [list, setList] = useState<any>([])
    const [name, setName] = useState<string>("")
    const [reset, setReset] = useState<boolean>(false)

    const fetchDataList = useCallback(async (page?: any) => {
        if (!channel || !id) return;
        const { pname } = u.searchIsParentList(category, channel, id)
        const _val = u.searchIdByChannelAndId(category, channel, id);
        setName(pname || _val?.name);

        const p = {
            channel: channel,
            page: page ?? 1
        } as any
      
        const res = await req2(p);
        if (res?.data) {
            setDta(res?.data);
            console.log(res?.data)
            setList(res?.data?.list?.data)
        }
        setReset(false)
    }, [category, channel, id])

    useEffect(() => {
        fetchDataList()
    }, [fetchDataList])

    return (
        <div className="grid gap10">
            <div className="mw1100 mt20">
                <Back>{name}</Back>
                <ImageList reset={reset} channel={channel} list={list} idkey="title" idValue="id" imgUrlkey="thumb" />
            </div>
            <Pagination lastPg={dta?.list?.last_page} total={dta?.list?.total} changeHandler={ async (e) => {
                await fetchDataList(e.current);
                setReset(true)
            }} />
            <Footer />
        </div>
    )
}
export default Sound