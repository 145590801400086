import 'swiper/swiper-bundle.min.css';
import { FC, useCallback, useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import Img from "@/components/img"
import styles from "./styles/pcdown.module.css"
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { PROD_SITE } from "@/const/prod.const";

import u, { ELocalKey, ProjectInfo, teseConstant } from "@/util";

SwiperCore.use([Autoplay, Pagination]);


const PCDownload: React.FC = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [versionData, setVersionData] = useState<any>({});
    const [responseData, setResponseData] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState(0);


    const handleResize = () => {
        setIsMobile(window.innerWidth <= 600); // Adjust for mobile screens
    };

    useEffect(() => {
        // Check screen size initially and add resize event listener
        window.addEventListener('resize', handleResize);
        handleResize();

        // Clean up the event listener
        return () => window.removeEventListener('resize', handleResize);
    }, []);

        // Fetch version.json
        useEffect(() => {
            fetch('/version.json')
                .then(response => response.json())
                .then(data => setVersionData(data))
                .catch(error => console.log(error));
        }, []);

        useEffect(() => {
            const downloadAPI = process.env.REACT_APP_STATIC_DOWNLOAD;
            if (!downloadAPI) {
                console.error("REACT_APP_STATIC_DOWNLOAD is not defined.");
                return;
            }

            fetch(downloadAPI)
                .then(response => response.json())
                .then(data => {
                    try {
                        console.log('show downloadapi', downloadAPI)

                        const decrypted = u.decrypt(data.data, data.suffix);
                        const parsedData = JSON.parse(decrypted);
                        setResponseData(parsedData);
                        if (document.getElementById('office') && parsedData?.official_website) {
                            document.getElementById('office')!.innerHTML = parsedData.official_website;
                        }                    } catch (e) {
                        console.error("Decryption error:", e);
                    }
                })
                .catch(error => console.log(error));
        }, []);


        const openLink = (type: string) => {
            if (!responseData) return;
    
            let urlToGo = '';
            switch (type) {
                case 'android_download1':
                    case 'android_download2':
                    case 'android_download3':
                        if(PROD_SITE === "1"){
                            urlToGo = responseData.android_app_download_1;
                        }else if(PROD_SITE === "2"){
                            urlToGo = responseData.android_app_download_2;
                        } else{
                            urlToGo = responseData.android_app_download_3;
                        }
                    break;
                case 'report':
                    urlToGo = responseData.feedback;
                    break;
                case 'telegram':
                    urlToGo = responseData.telegram_group;
                    break;
                case 'business':
                    urlToGo = responseData.merchant;
                    break;
                case 'email':
                    urlToGo = responseData.official_email;
                    break;
                case 'qrcode':
                    urlToGo = responseData.official_qr;
                    break;
                case 'home':
                    urlToGo = responseData.official_website;
                    break;
                default:
                    urlToGo = responseData.official_website;
            }
    
            if (!urlToGo.includes('http')) {
                urlToGo = 'https://' + urlToGo;
            }
    
            window.location.href = urlToGo;
        };


        const downloadFile = (type: string) => {
            if (!versionData || !responseData) return;
    
            const params = new URLSearchParams(window.location.search);
            const paramInvite = params.get('invite');
            if (paramInvite) {
                const input = document.createElement('input');
                input.value = paramInvite;
                document.body.appendChild(input);
                input.select();
                document.execCommand('copy');
                document.body.removeChild(input);
            }
    
            const targetUrl = versionData[type + '_url'] || responseData.android_app_download_1 || 'https://maomiav.com';
            window.location.href = targetUrl;
        };

        const copyText = (type: string) => {
            const targetUrl = versionData[type + '_url'] || responseData.official_website || 'https://maomiav.com';
            const input = document.createElement('input');
            input.value = targetUrl;
            document.body.appendChild(input);
            input.select();
            document.execCommand('copy');
            document.body.removeChild(input);
        };

    const bannerList = [
        { img: "/assets/images/pc/img_slide1.png", alt: "Slide 1" },
        { img: "/assets/images/pc/img_slide2.png", alt: "Slide 2" },
        { img: "/assets/images/pc/img_slide3.png", alt: "Slide 3" }
    ];

    const handleDotClick = (index: number) => {
        const swiperElement = document.querySelector(".swiper") as HTMLElement & { swiper?: any };

        if (swiperElement?.swiper) {
            swiperElement.swiper.slideToLoop(index); // Call Swiper's method
            setActiveIndex(index); // Update state
        }
    };

    return (
        <div style={{background:'#000'}}>
        <div className={styles.container}>
            {/* Swiper Section */}
            <Swiper
                loop
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                className={styles.swiper}
                onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)} // Update active index

            >
                {bannerList.map((x, i) => (
                    <SwiperSlide key={i}>
                        <img 
                            style={{ width: "100%", height: '100%', objectFit: 'cover' }} 
                            alt={x.alt} 
                            src={x.img} 
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={styles.paginationContainer}>
                    {bannerList.map((_, index) => (
                        <div
                            key={index}
                            className={styles.paginationDot}
                            onClick={() => handleDotClick(index)}
                            style={{background: index === activeIndex ? "white" : "grey", height: index === activeIndex ? "40px" : "20px"}}
                        ></div>
                    ))}
                </div>

            {/* Top Container with images */}
            <div className={styles.top_container}>
                <img onClick={() => openLink('home')} src="/assets/images/pc/img_home.png" alt="Home" />
                <img onClick={() => openLink('report')} src="/assets/images/pc/img_report.png" alt="Report" />
            </div>

            {/* Download Section */}
            <div className={styles.down_container}>
                <img onClick={() => openLink('android_download1')} src="/assets/images/pc/img_download_android1.png" alt="Download 1" />
                <img onClick={() => openLink('android_download2')} src="/assets/images/pc/img_download_android2.png" alt="Download 2" />
                <img onClick={() => openLink('android_download3')} src="/assets/images/pc/img_download_android3.png" alt="Download 3" />
            </div>

            {/* Footer */}
            <img src="/assets/images/pc/img_bottom.png" alt="Footer" className={styles.footer_container}/>
        </div>
        </div>

    );
};

export default PCDownload;
